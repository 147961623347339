html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Baloo Bhai 2", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: "Baloo Bhai 2", sans-serif !important;
}

a {
  color: #2e94cd;
  text-decoration: none;
}

/*  ======================================== INQUIRY MODULE CSS START ================================== */
/*  ======================================== INQUIRY MODULE CSS START ================================== */

/* INQURICY CHAT SCROLLBAR CSS START */

.chat-outer-box::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}
/* Track */

.chat-outer-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chat-outer-box::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.chat-outer-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* SCROLLBAR CSS END */

/*  ======================== BOUNCING LOADER FOR INQUIRY PAGE START ===================================== */

.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 10px 20px;
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-10px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
/*  ======================== BOUNCING LOADER FOR INQUIRY PAGE END ===================================== */

/*  ======================================== INQUIRY MODULE CSS END ================================== */
/*  ======================================== INQUIRY MODULE CSS END ================================== */

/* CUSTOM COMMON SROLL BAR START */

.custom-scrollbar::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
/* Track */

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

/* CUSTOM COMMON SROLL BAR END */

.react-joyride__spotlight {
  border: 5px solid #0b486b !important;
  box-shadow: 1px 2px 1px #0b486b, -1px -2px 1px #0b486b;
}
