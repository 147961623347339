:root {
  --gray: #a1a4ac21;
  --primary: #2e94cd;
}

/* ================== OUTLINE SADHOW ==============  */
.outlined-shadow:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}
/* ============ MENU BAR ============ */
.selected-menu {
  background-color: var(--gray) !important;
  border-radius: 14px !important;
  font-weight: 600 !important;
  padding: 10px !important;
}

.selected-menu .MuiTypography-root {
  font-weight: 600 !important;
}

.un-selected-menu {
  border-radius: 14px !important;
  padding: 10px !important;
}

.un-selected-menu:hover {
  background-color: var(--gray);
}

/* ============ LOADER ============ */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

/* ===================================== CUSTOM CALENDOR CSS ===================================== */
.custom-clear-button {
  position: absolute !important;
  top: 2px !important;
  right: 23px !important;
}

.custom-popper-classname-date-range
  .react-datepicker
  .react-datepicker__header {
  background-color: #fff !important;
}

.custom-popper-classname-date-range
  .react-datepicker
  .react-datepicker__day--in-selecting-range {
  background-color: rgba(46, 148, 205, 0.4) !important;
  color: #fff !important;
}
.custom-popper-classname-date-range
  .react-datepicker__month
  .react-datepicker__day--keyboard-selected {
  background-color: var(--primary) !important;
  color: #fff !important;
}

/* === PRIMARY DATE RANGE */

.primary-date-range-pooper .react-datepicker .react-datepicker__header {
  background-color: #fff !important;
}

.primary-date-range-pooper
  .react-datepicker
  .react-datepicker__day--in-selecting-range {
  background-color: rgba(46, 148, 205, 0.4) !important;
  color: #fff !important;
}
.primary-date-range-pooper
  .react-datepicker__month
  .react-datepicker__day--keyboard-selected {
  background-color: var(--primary) !important;
  color: #fff !important;
}

.primary-date-range-pooper .react-datepicker {
  min-width: 350px;
}
/* =============================================== UPLOAD FILE ========================================= */
.dropzone-custom-file:hover button {
  background-color: #144c6d;
}
.dropzone-custom-file:hover > div {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  transition: all 200ms;
}

/* ====================================== TESTIMONIAL SWIPER CSS =================================== */

.testimonial-swiper .swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
}

.testimonial-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--primary);
}

@media screen and (max-width: 400px) {
  .support-mail {
    text-wrap: wrap;
    word-break: break-all;
  }
}

/* =============================================== INQUIRY ========================================= */
.inquiry-prefix-text {
  display: -webkit-box;
  height: 60px;
  -webkit-line-clamp: 2;
  line-clamp: inherit;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
